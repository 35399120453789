// TODO: Refactor this file to remove all the eslint-disable
import * as React from 'react';
import alert from '../../entities/icons/alert_indicator.png';
import tick from '../../entities/icons/tick_upscale.png';

function RenderDataHealthIcon(icon) {
  switch (icon) {
    case 'alert':
      return alert;
    case 'tick':
      return tick;
    default:
      return icon;
  }
}

interface InsightDataHealthHeaderProps {
  data: {
    icon: string;
    color: string;
    header: string;
    text: string;
    link?: string;
  };
}

const InsightDataHealthHeader = ({ data }:InsightDataHealthHeaderProps) => {
  function openTab(event) {
    window.open(data.link);
    event.preventDefault();
  }
  return (
    <div className='dataHealthStatus'>
      <img width='20' src={RenderDataHealthIcon(data.icon)} alt='' /> <b style={{ color: data.color }}>{data.header}</b>
      {data.text}
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      {data.link ? <a href='#' onClick={openTab} target='_blank'><b>Fix in Xero &gt;</b></a> : null}
    </div>
  );
};

export default InsightDataHealthHeader;
