import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AccountEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import ClientSettingsModal from '../../components/modals/ClientSettingsModal';
import { ClientSettingsPages } from '../../models/enums/modals';

type Props = {
  relevantSettings: string[],
  insightKey?: string,
}

const InsightSettingsInfo = ({ relevantSettings, insightKey }: Props) => {
  const rootStore = useStore();
  const settingType = (relevantSettings && relevantSettings.length) ? relevantSettings[0] : 'None';

  useEffect(() => {
    if (settingType === 'bankAccounts') {
      rootStore.businessesStore.fetchBankAccounts(
        rootStore.businessesStore.selectedBusinessId
      );
    }
  }, [settingType]);

  const getSettingsText = () => {
    switch (settingType) {
      case 'incomeTax': {
        const businessConfig = rootStore.businessesStore?.selectedBusinessConfig;
        const taxRate = rootStore.businessesStore?.selectedBusiness?.profile?.taxRate;
        const defaultTaxRate = rootStore.businessesStore?.defaultTaxRate;
        const accountsLength = businessConfig?.overrides?.incomeTaxAccounts?.length;

        const taxRateString = `Income tax rate ${!taxRate || taxRate === ''
          ? defaultTaxRate
          : taxRate}%.`;

        const accountsString = accountsLength ? `Based on ${accountsLength} accounts selected.` : 'May be affected by client business tax and compliance settings.';

        return `${taxRateString} ${accountsString}`;
      }
      case 'taxAgentExtension': {
        const prefix = rootStore.businessesStore?.selectedBusiness.profile.taxAgent ? 'Has' : 'Doesn\'t have';
        return `${prefix} AU Tax Agent Extension.`;
      }
      case 'bankAccounts': {
        if (rootStore.businessesStore.selectedBusinessBankAccounts && rootStore.businessesStore.selectedBusinessBankAccounts.length > 0) {
          const selectedNumber = rootStore.businessesStore?.selectedBusiness.profile?.hasSelectedBankAccount?.length
            ? rootStore.businessesStore?.selectedBusiness?.profile?.hasSelectedBankAccount?.length
            : 'all';
          return `Based on ${selectedNumber} of the ${rootStore.businessesStore.selectedBusinessBankAccounts.length} active bank accounts.`;
        }

        return 'May be affected by client business settings. ';
      }
      case 'profitability': {
        const businessConfig = rootStore.businessesStore?.selectedBusinessConfig;

        switch (insightKey) {
          case 'netProfit':
            return 'Based on Revenues, Direct Costs, and Operating Expenses settings above.';
          case 'grossProfit':
            return 'Based on Revenues and Direct Costs settings above.';
          default: {
            const accountsLength = businessConfig?.overrides?.[AccountEnums.InsightToAccountSettingMap[insightKey]]?.length;
            if (!accountsLength) {
              return 'May be affected by client business profit and loss settings. ';
            }

            return `Based on ${accountsLength} accounts selected.`;
          }
        }
      }
      default:
        return 'May be affected by client business settings. ';
    }
  };

  return (
    <div className='insightSettingsContainer' style={{ marginBottom: 20, width: '36vw' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: 20 }}>
          <div className='insightSettingsHeader'>
            Settings
          </div>
          <div
            className='insightSettingsBody'
          >
            {getSettingsText()}
            <b
              onClick={() => {
                ClientSettingsModal(rootStore, ClientSettingsPages.CLIENT_CONFIGURATION);
              }}
              style={{ color: '#4264ea', cursor: 'pointer' }}
            >{' Change >'}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

InsightSettingsInfo.defaultProps = {
  insightKey: '',
};

export default observer(InsightSettingsInfo);
