import React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import Format, { FormatTypes, ValueTypes } from '@aider/aider-formatting-library';
import { ClientManagementEnums } from '@aider/constants-library';
import { ConnectionDataStatus } from '@aider/constants-library/dist/enums/clientManagement';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import DashboardDataStatus from '../dashboard/DashboardDataStatus';
import { useStore } from '../../stores/Store';
import { XeroLogoScaleable } from '../icons';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import ResyncError from '../manualResync/ResyncError';
import { InsightTab } from '../../stores/v1/pageStore';
import ClientListRedirect from '../dashboard/ClientListRedirect';

const PeriodCloseDashboardNameCell = ({ name, record }) => {
  const navigate = useNavigate();
  const { businessesStore, resyncStore, connectionsStore, checklistStore } = useStore();
  const handleReconnectClick = (osp: ClientManagementEnums.OSPKeys) => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };
  const handleKeyCellClick = (entry) => {
    ClientListRedirect({ businessId: entry.businessId, businessName: entry.businessName }, InsightTab.dataCompliance);
    checklistStore.activeChecklistType = entry.checklistType;
    checklistStore.activePeriod = entry.period_key;
    navigate('/insights');
  };

  const business = businessesStore.businesses.get(record.businessId);
  const ospKey = business?.connectedOsps?.[0] || ClientManagementEnums.OSPKeys.xero;
  const format = new Format(business?.currencyCode || null, business?.countryCode || null);

  const determineDataStatus = () => {
    const dataStatus = resyncStore.dataStatusSnapshots.has(record.businessId) ? resyncStore.dataStatusSnapshots.get(record.businessId) : null;
    if (business.profile?.dataStatus === ConnectionDataStatus.disconnected) {
      return 'Disconnected';
    }

    if (resyncStore.error?.get(record.businessId)) {
      return 'Could not finish update... Please try again';
    }

    if (resyncStore.dataStatusPendingData?.get(record.businessId) || resyncStore.resyncingBusinesses?.get(record.businessId)) {
      return 'Updating data... please wait';
    }

    if (resyncStore.dataStatusSnapshots?.get(record.businessId)?.etl?.ended) {
      return `Updated: ${format.formatValue({
        format: ValueTypes.formatDate,
        value: new Date(
          dataStatus.etl.ended.toDate()
        ).toISOString(),
        dateFormat: {
          day: FormatTypes.short,
          month: FormatTypes.short,
          time: true,
        },
      })}`;
    }

    return '';
  };

  const statusType = business.profile?.dataStatus === 'disconnected' ? 'disconnected' : 'updated';

  return (
    <>
      <Tooltip
        placement='right'
        title=''
      >
        <ButtonPrimary
          key={record.key}
          className='business-name-button'
          type='link'
          mixpanelEvent='Compliance Dashboard: Client name clicked'
          onClick={() => handleKeyCellClick(record)}
        >{name}
        </ButtonPrimary>
        <div className='business-name-button__data-status'>
          <span className={`business-name-button__data-status__${statusType}`}>
            {(business.profile?.dataStatus === 'disconnected') && (<span className='osp__logo'><XeroLogoScaleable /></span>)}
            {determineDataStatus()}
            <ResyncError className='resync-error__dashboard-status' businessId={record.businessId} />
          </span>
          {(business.profile?.dataStatus !== 'disconnected') && (
            <DashboardDataStatus key={`dashboard-data-status-${record.businessId}`} businessId={record.businessId} businessName={name} businessOsp={ospKey} />
          )}
          {(business.profile?.dataStatus === 'disconnected') && (
            <ButtonSecondary
              size='small'
              ghost
              className='reconnect'
              onClick={() => handleReconnectClick(ClientManagementEnums.OSPKeys.xero)}
            >
              Reconnect
            </ButtonSecondary>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default observer(PeriodCloseDashboardNameCell);
