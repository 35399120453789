/* eslint-disable global-require */
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { CatchAll } from '@aider/constants-library/dist/types/generic';
import type { RootStore } from './Store';

import handleError from '../lib/errorHandler';

const langfiles = {
  en: require('../locales/en.json'),
  'en-US': require('../locales/en-US.json'),
  'en-AU': require('../locales/en-AU.json'),
};

export default class LocaleStore {
  rootStore: RootStore;

  loading: string[] = [];

  defaultLocale: any;

  locale: any;

  setLocale = (countryCode: string) => {
    const localeCode = this.availableLocales[countryCode];
    if (!localeCode) {
      this.locale = null;
      return;
    }
    this.loadLocale(localeCode)
      .then((locale) => {
        this.locale = locale;
      })
      .catch((error) => {
        handleError({ error });
        this.locale = null;
      });
  };

  availableLocales = {
    US: 'en-US',
    AU: 'en-AU',
  };

  loadLocale = async (localeCode: string) => langfiles?.[localeCode];

  getLocaleTranslation = (key: string) => _.get(this.locale, key);

  getDefaultTranslation = (key: string) => _.get(this.defaultLocale, key);

  translation = (key: string, options?: CatchAll<string | number>) => {
    const translation =
      this.getLocaleTranslation(key) || this.getDefaultTranslation(key);
    if (!translation) {
      return `[Translation not found: ${key}]`;
    }
    if (options) {
      try {
        return _.template(translation)(options);
      } catch (error) {
        handleError({ error });
        return `[Translation error: ${key}]`;
      }
    }
    return translation;
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    this.rootStore.loadingStore.setLoading('locale');

    this.loadLocale('en').then((locale) => {
      this.defaultLocale = locale;
      this.rootStore.loadingStore.setDoneLoading('locale');
    });
  }
}
