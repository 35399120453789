import React, { useMemo } from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { useStore } from '../../stores/Store';
import { InsightTab } from '../../stores/v1/pageStore';

const WysiwygQuickContent = () => {
  const { reportTemplateStore, localeStore } = useStore();
  const filteredInsightData = reportTemplateStore.filteredInsightGraphData;

  const handleSelect = (insightTitle) => {
    reportTemplateStore.injectTrendText(insightTitle);
  };

  const data = useMemo(() => filteredInsightData.reduce((acc, insight) => {
    if ((insight.categoryData?.categoryId as InsightTab) === InsightTab.profitability) {
      acc.push({
        label: localeStore.translation(`insights.${insight.insightKey}.title`),
        value: `#{${localeStore.translation(`insights.${insight.insightKey}.title`).replace(' ', '').toLowerCase()}Trend}`,
      });
    }
    return acc;
  }, []), [filteredInsightData]);

  return (
    <Select
      showSearch
      className='wysiwyg-custom-dropdown'
      popupClassName='ant-tree-dropdown'
      onChange={handleSelect}
      options={data}
      placeholder='Insert Quick Content'
      value={null}
      suffixIcon={<CaretDownFilled />}
    />
  );
};

export default observer(WysiwygQuickContent);
