import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import DownloadButton from './performanceReport/DownloadButton';
import InsightSelector from './performanceReport/InsightSelector';
import ReportContent from './performanceReport/ReportContent';

const PerformanceReport = () => {
  const rootStore = useStore();
  const { businessStore, reportDownloadStore, timePeriodStore } = rootStore;

  const insightsAvailable: string[] = businessStore.insightData
    // eslint-disable-next-line no-prototype-builtins
    .filter((insight) => insight.hasOwnProperty('chartSummary'))
    .map((insight) => insight.insightKey);

  useEffect(() => {
    reportDownloadStore.copyInsights();
    reportDownloadStore.resetGeneratedContent();
  }, [businessStore.selectedBusinessId]);

  useEffect(() => {
    reportDownloadStore.resetGeneratedContent();
  }, [timePeriodStore.profitabilityPeriodSelected]);

  reportDownloadStore.mapInsights(reportDownloadStore.insightsSelected);

  return (
    <div className='performance-report'>
      <InsightSelector
        insightsAvailable={insightsAvailable}
      />
      <div className='report-main'>
        <ReportContent
          insightsAvailable={insightsAvailable}
        />
        <DownloadButton />
      </div>
    </div>
  );
};

export default observer(PerformanceReport);
