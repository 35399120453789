import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Format, { FormatTypes, ValueTypes } from '@aider/aider-formatting-library';
import { AlertEnums, ClientManagementEnums, DashboardEnums } from '@aider/constants-library';
import { ConnectionDataStatus } from '@aider/constants-library/dist/enums/clientManagement';
import { cleanMainValue, getCellClassName, getIconPosition } from '../../lib/componentHelpers/dashboardHelpers';
import { useStore } from '../../stores/Store';
import ClientListRedirect from './ClientListRedirect';
import DashboardDataStatus from './DashboardDataStatus';
import useIsInViewport from '../../lib/customHooks/useIsInViewport';
import { InsightTab } from '../../stores/v1/pageStore';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { XeroLogoScaleable } from '../icons';
import ResyncError from '../manualResync/ResyncError';

const format = new Format();

const DashboardBusinessNameCell = ({ businessId }: { businessId: string }) => {
  const { businessesStore, dashboardStore, resyncStore, connectionsStore } = useStore();
  const handleReconnectClick = (osp: ClientManagementEnums.OSPKeys) => {
    connectionsStore.connectionType = osp;
    connectionsStore.modalAddConnection();
  };

  const navigate = useNavigate();
  const business = dashboardStore.businesses.get(businessId) || businessesStore.businesses.get(businessId);
  const businessDetails = businessesStore.businesses.get(businessId);
  const [ospKey] = businessDetails?.connectedOsps || [null];
  const groupPositions = { first: true, last: true };
  let colKey = 'client';
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useIsInViewport(ref);
  const [cellHasRendered, setCellHasRendered] = React.useState<boolean>(false);

  const handleCellClick = () => {
    // This is a workaround using the existing redirect functionality until we refactor
    // the insights page
    ClientListRedirect({ businessId, businessName: business.businessName }, InsightTab.overview);
    navigate('/insights');
  };

  React.useEffect(() => {
    if (isVisible) {
      setCellHasRendered(true);
    }
  }, [isVisible]);

  const dataStatus = resyncStore.dataStatusSnapshots?.get(businessId);
  let subText = null;
  if (resyncStore.error?.get(businessId)) {
    subText = 'Could not finish update... Please try again';
  } else if (resyncStore.dataStatusPendingData?.get(businessId) || resyncStore.resyncingBusinesses?.get(businessId)) {
    subText = 'Updating data... please wait';
  } else if (businessDetails?.profile?.dataStatus === ConnectionDataStatus.disconnected) {
    colKey = 'client disconnected';
    subText = 'Disconnected';
  } else if (resyncStore.dataStatusSnapshots?.get(businessId)?.etl?.ended) {
    subText = `Updated: ${format.formatValue({
      format: ValueTypes.formatDate,
      value: new Date(
        dataStatus?.etl?.ended.toDate()
      ).toISOString(),
      dateFormat: {
        day: FormatTypes.short,
        month: FormatTypes.short,
        time: true,
      },
    })}`;
  }

  // Render
  if (colKey === 'client disconnected') {
    return (
      <div className={getCellClassName(groupPositions, AlertEnums.Type.ALERT_GREY, DashboardEnums.ColumnTypes.name, colKey, dashboardStore)} onClick={handleCellClick}>
        <div className='dashboardCell__data'>
          <div className='dashboardCell__value'>{cleanMainValue(colKey, business.businessName)}</div>
          <div className='dashboardCell__subtext'>
            <span className='osp__logo'><XeroLogoScaleable /></span>{subText}
          </div>
        </div>
        <div ref={ref} className={`dashboardCell__icon dashboardCell__icon--${getIconPosition(DashboardEnums.ColumnTypes.name)}`}>
          {(isVisible || cellHasRendered) && (
          <ButtonSecondary
            size='small'
            ghost
            className='reconnect'
            onClick={() => handleReconnectClick(ClientManagementEnums.OSPKeys.xero)}
            mixpanelEvent='Button Click - Reconnect Client'
          >
            Reconnect
          </ButtonSecondary>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={getCellClassName(groupPositions, AlertEnums.Type.ALERT_GREY, DashboardEnums.ColumnTypes.name, colKey, dashboardStore)} onClick={handleCellClick}>
      <div className='dashboardCell__data'>
        <div className='dashboardCell__value'>{cleanMainValue(colKey, business.businessName)}</div>
        <div className='dashboardCell__subtext'>
          {subText}
          <ResyncError businessId={businessId} />
        </div>
      </div>
      <div ref={ref} className={`dashboardCell__icon dashboardCell__icon--${getIconPosition(DashboardEnums.ColumnTypes.name)}`}>
        {(isVisible || cellHasRendered) && (
        <DashboardDataStatus key={`dashboard-data-status-${businessId}`} businessId={businessId} businessName={business.businessName} businessOsp={ospKey} />
        )}
      </div>
    </div>
  );
};

export default observer(DashboardBusinessNameCell);
