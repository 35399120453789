import * as React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { HelpTitle, mapInsightToHelpKey, selectIndex } from '../../entities/helpText';
import helpInfo from '../../entities/icons/icon_help.png';
import { rootStore } from '../../stores/Store';
import ButtonLink from './ButtonLink';
import renderVisibility from './RenderVisibility';
import { EstimateConfidenceBanner } from '../molecules/EarlyWarning';

type Props = {
  insightData: any;
  trackMixpanelEvent: (event: string) => void;
  renderActionCentreModal: (event: string) => void;
  ix: number;
};

const InsightTrend = ({
  insightData, trackMixpanelEvent, renderActionCentreModal, ix,
}:Props) => (
  <div className='insightTrendContainer' style={{ marginBottom: insightData?.insightDescription ? 40 : 0 }}>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        {insightData?.chartSummary && (
          <>
            <div className='insightTextHeader'>
              Trends {mapInsightToHelpKey(insightData.insightKey) ? (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                  alt=''
                  style={{ cursor: 'pointer', width: 20 }}
                  onClick={() => {
                    trackMixpanelEvent('Insight Help');
                    selectIndex(insightData.insightKey, rootStore);
                  }}
                  src={helpInfo}
                />
            ) : null}
            </div>
            {insightData?.earlyWarning && (
            <EstimateConfidenceBanner
              confidence={insightData.earlyWarning?.confidence}
              insightKey={insightData.insightKey}
            />
          )}
            <div className={`insightBody ${insightData.summaries[1].severity !== undefined ? renderVisibility(insightData.summaries[1].severity.alertLevel) : ''}`}>
              {insightData.chartSummary[0].body}
            </div>
            {mapInsightToHelpKey(insightData.insightKey)
            && (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className='insightTrendSettings'
              style={{
                display: 'flex',
                position: 'relative',
                cursor: 'pointer',
              }}
              onClick={() => {
                trackMixpanelEvent('Insight Help');
                rootStore.helpPanelStore.setHelpItemVisible(HelpTitle.CALCULATION);
                rootStore.helpPanelStore.setHelpItemVisible(HelpTitle.DATA);
                selectIndex(insightData.insightKey, rootStore);
              }}
            >
              How is this calculated <RightOutlined />
            </div>
            )}
          </>
        )}
      </div>
    </div>

    <div className='insightActionContainerEmbedded'>
      {insightData?.externalLink
              && <ButtonLink className='white-button' text='Go to Xero' link={insightData?.externalLink.link} type='externalLink' insightData={insightData.graph.title} />}
      {insightData?.actions
        ? <ButtonLink id={`send_${insightData?.insightKey}`} className='blue-button' text='Share Insight' type='email' insightData={insightData} renderActionCentreModal={renderActionCentreModal} ix={ix} />
        : null}
    </div>
  </div>
);

export default InsightTrend;
