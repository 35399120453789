import React from 'react';
import { Form, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { renderToString } from 'react-dom/server';
import { useStore } from '../../../stores/Store';
import WysiwygEditor from '../WysiwygEditor';
import AiderAlert from '../../AiderAlert';

const TextComponent = ({
  className,
  block,
}: {
  className: string,
  block: PracticeTypes.ReportBlock,
}) => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const isEditing = reportTemplateStore.editBlock === block.id;

  const customEntityTransform = (entity, text) => {
    const tempVar = reportTemplateStore.formattedTemplateVariables[text.replace('#{', '').replace('}', '')];
    const unsupported = tempVar?.toLowerCase().includes('data not available');
    if (entity.type === 'MENTION' && tempVar && !unsupported) return null;
    if (!tempVar || unsupported) {
      return renderToString(<AiderAlert type='warning' title='Trend data not available for selected period' />);
    }
    return `<a href="${entity.data.url}" class="wysiwyg-trend" data-trend data-value="${entity.data.value}">${text}</a>`;
  };

  return (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {isEditing ? (
        <WysiwygEditor
          blockType={block.type}
          content={block.content}
          variables={reportTemplateStore?.flatSortedTemplateVariables}
          onChange={(content) => { // eslint-disable-line @typescript-eslint/no-unused-vars
            // This does nothing other than run the action which allows
            // the form to be updated with the new content. It requires
            // the new value to be passed in, but we don't need to do anything with it.
          }}
        />
      ) : (
        (block.content && (block.content.blocks.length > 1 || block.content.blocks[0].text !== '')
          ? Object.keys(reportTemplateStore.formattedTemplateVariables).length === 0 || reportTemplateStore.fetchingVariables
            ? (
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
            ) : (
              <Markdown className={`${className}--${block.type}--content`}>
                {_.template(draftToHtml(block.content, null, null, customEntityTransform), { interpolate: /[#]{([a-zA-Z0-9.]+?)}/g })(reportTemplateStore.formattedTemplateVariables)}
              </Markdown>
            ) : (
              <AiderAlert
                title={`Empty ${block.type === 'header' ? 'title' : 'text'} block`}
              />
          )
        )
      )}
    </Form.Item>
  );
};

export default observer(TextComponent);
