import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { EditorState, convertToRaw, convertFromRaw, Entity, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PracticeTypes } from '@aider/constants-library';
import { ReportContent } from '@aider/constants-library/dist/types/draftjstypes';
import WysiwygDeleteButton from './WysiwygDeleteButton';
import { WYSIWIG_TOOLBAR_OPTIONS } from '../../models/constants/components';
import { useStore } from '../../stores/Store';
import WysiwygVariableSelector from './WysiwygVariableSelector';
import WysiwygQuickContent from './WysiwygQuickContent';

interface ContentEditorProps {
  content: ReportContent
  onChange: (content: string) => void;
  blockType: PracticeTypes.ReportBlock['type'];
  variables?: any[];
}

const WysiwygEditor: React.FC<ContentEditorProps> = ({ content, onChange, blockType, variables }) => {
  const { reportTemplateStore } = useStore();
  const [state, setState] = useState(
    content ? EditorState.createWithContent(convertFromRaw(content)) : EditorState.createEmpty()
  );
  const { inject, injectEntity } = reportTemplateStore;

  let editorRef;

  const setEditorRef = (ref) => {
    editorRef = ref;
  };

  const decorators = [{
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'TREND';
      }, callback);
    },
    component: (props) => {
      const { children } = props;
      return <a className='rdw-trend-link'>{children}</a>;
    }
  }];

  const focusEditorEnd = () => {
    editorRef.focusEditor();
    const editorState = EditorState.moveSelectionToEnd(editorRef.state.editorState);
    setState(EditorState.forceSelection(editorState, editorState.getSelection()));
  };

  React.useEffect(() => {
    focusEditorEnd();
  }, []);

  const customOptions = [
    <WysiwygDeleteButton />,
  ];

  if (blockType === 'text') {
    customOptions.unshift(
      <WysiwygQuickContent />,
      <WysiwygVariableSelector />,
    );
  }

  React.useEffect(() => {
    if (inject) {
      if (!state.hasFocus) {
        focusEditorEnd();
      }
      const contentState = state.getCurrentContent();
      const entityKey = Entity.create('TREND', 'IMMUTABLE', inject);
      const modifiedContent = Modifier.insertText(
        contentState,
        state.getSelection(),
        inject,
        null,
        entityKey
      );
      setState(EditorState.push(state, modifiedContent, 'insert-fragment'));
    }
    reportTemplateStore.inject = null;
  }, [inject]);

  React.useEffect(() => {
    if (injectEntity) {
      if (!state.hasFocus) {
        focusEditorEnd();
      }

      const contentState = editorRef.state.editorState.getCurrentContent();
      const entityKey = Entity.create('MENTION', 'IMMUTABLE', injectEntity);
      const modifiedContent = Modifier.insertText(
        contentState,
        editorRef.state.editorState.getSelection(),
        injectEntity,
        null,
        entityKey
      );

      editorRef.onChange(EditorState.push(state, modifiedContent, 'insert-fragment'));

      reportTemplateStore.injectEntity = null;
    }
  }, [injectEntity]);

  return (
    <Editor
      autofocus
      ref={setEditorRef}
      editorState={state}
      onEditorStateChange={(newState) => {
        setState(newState);
        onChange(convertToRaw(state.getCurrentContent()));
      }}
      wrapperClassName='wysiwyg'
      editorClassName={`wysiwyg__editor scrollbar wysiwyg__editor__${blockType}`}
      toolbarClassName='wysiwyg__toolbar'
      mention={variables && {
        separator: ' ',
        trigger: '#',
        suggestions: variables
      }}
      customDecorators={decorators}
      toolbar={
        {
          options: WYSIWIG_TOOLBAR_OPTIONS?.[blockType] || WYSIWIG_TOOLBAR_OPTIONS.text,
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            className: undefined,
            dropdownClassName: undefined,
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center']
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          },
          history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
          },
        }
      }
      toolbarCustomButtons={customOptions}
      localization={{
        locale: 'en',
        translations: {
          'components.controls.blocktype.h1': 'Heading 1',
          'components.controls.blocktype.h2': 'Heading 2',
          'components.controls.blocktype.h3': 'Heading 3',
          'components.controls.blocktype.h4': 'Heading 4',
          'components.controls.blocktype.h5': 'Heading 5',
          'components.controls.blocktype.h6': 'Heading 6',
          'components.controls.blocktype.normal': 'Paragraph',
          'components.controls.link.linkTitle': 'Text to display',
          'components.controls.link.linkTarget': 'Link to',
        }
      }}
    />
  );
};

WysiwygEditor.defaultProps = {
  variables: null,
};

export default observer(WysiwygEditor);
