import React, { useState } from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';

import { PracticeTypes } from '@aider/constants-library';
import { SendOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import NiceModal from '@ebay/nice-modal-react';
import { useStore } from '../../../stores/Store';
import AssistantLoader from '../../AdvisoryAssistant/AssistantLoader';
import Notification from '../../Notification';
import AiderAlert from '../../AiderAlert';
import GenAI from '../../icons/GenAIWhite';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import ButtonSecondary from '../../customAntD/ButtonSecondary';

interface LLMAssistantContentProps {
  block: PracticeTypes.ReportBlock;
}

const LLMAssistantContent = ({ block }: LLMAssistantContentProps) => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const [prompt, setPrompt] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const originalContent = _.template(
    draftToHtml(block.content),
    { interpolate: /#{([\s\S]+?)}/g }
  )(reportTemplateStore.templateData);

  const cleanupConversation = () => {
    reportTemplateStore.editBlock = null;
    reportTemplateStore.clearReportContent();
    setPrompt(null);
    NiceModal.hide('LLMAssistantModal');
  };

  const useLlm = () => {
    reportTemplateStore.setEditBlock(block);
    reportTemplateStore.useReportContent();
    cleanupConversation();
  };

  const getLLMContent = (context) => {
    setLoading(true);
    reportTemplateStore
      .updateLLMReportContent(prompt, context)
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Something went wrong',
          description: 'Could not generate AI content. Please try again.',
        });
      })
      .finally(() => {
        setPrompt(null);
        setLoading(false);
      });
  };

  const submitPrompt = async () => {
    const context: any = { originalContent };
    if (reportTemplateStore?.updatedReportContent) {
      context.previousVersion = reportTemplateStore?.updatedReportContent;
    }

    getLLMContent(context);
  };

  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  return (
    <>
      <section className='advisory-llm-modal'>
        <AiderAlert title='Existing Text' type='info'>
          <ButtonSecondary className='advisory-llm-modal__button' onClick={cleanupConversation}>
            Keep Original Content
          </ButtonSecondary>
          <Markdown
            className='advisory-llm-modal__content scrollbar'
          >
            {originalContent}
          </Markdown>
        </AiderAlert>
        <AiderAlert title='AI Generated Text' type='info' icon={<GenAI />} ghost>
          {loading
            ? (
              <div className='place-center'>
                <AssistantLoader />
              </div>
            ) : (
              <>
                {reportTemplateStore?.updatedReportContent && (
                  <ButtonPrimary className='advisory-llm-modal__button' onClick={useLlm}>
                    Use Generated Content
                  </ButtonPrimary>
                )}
                <Markdown className='advisory-llm-modal__content scrollbar'>
                  {reportTemplateStore?.updatedReportContent || 'Submit your prompt to get started.'}
                </Markdown>
              </>
            )}
        </AiderAlert>
      </section>
      <section className='advisory-llm-modal__footer'>
        <Input.TextArea
          disabled={loading}
          rows={5}
          placeholder='Enter your prompt'
          value={prompt}
          onChange={handleChange}
        />
        <ButtonPrimary
          type='primary'
          shape='circle'
          size='large'
          loading={loading}
          disabled={loading}
          icon={<SendOutlined />}
          onClick={submitPrompt}
        />
      </section>
    </>
  );
};

export default observer(LLMAssistantContent);
