import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { SplitEnums } from '@aider/constants-library';
import CheckList from './CheckList';
// eslint-disable-next-line import/no-named-as-default
import Insight from './Insight';
import { Welcome } from '../../entities/components';
import notes from '../../entities/icons/notes.png';
import { TabItems } from '../../entities/types';
import { InsightComponentProps } from '../../ts/interfaces/components/insights';

import { InsightType } from '../../ts/enums/Constants';
import PeriodGranularity from '../../components/PeriodGranularity';
import AdvisoryAi from './AdvisoryAi';
import PerformanceReport from './PerformanceReport';
import { ShimmerLoader } from '../../components/dataCompliance/ShimmerLoader';
import { useStore } from '../../stores/Store';
import { InsightTab } from '../../stores/v1/pageStore';
import Overview from '../../components/clientTransactionSummary/Overview';
import PerformanceReportV2 from '../../components/reports/PerformanceReport';

const InsightComponent = ({
  insightsLoading,
  insightData,
  renderActionCentreModal,
  type,
  uppercaseType,
  lowercaseType,
  toggleModal,
}: InsightComponentProps) => {
  const rootStore = useStore();
  const { checklistStore, businessesStore, splitStore } = rootStore;
  useEffect(() => {
    if (insightData) {
      rootStore.timePeriodStore.getInsightOptions(insightData);
      if (!rootStore.timePeriodStore.profitabilityPeriodSelected) {
        rootStore.timePeriodStore.setProfitabilityPeriodSelected(rootStore.timePeriodStore.profitabilityPeriods[0].value);
      }
    }
  }, [insightData, rootStore.timePeriodStore.profitabilityPeriodSelected]);

  if (
    insightsLoading && type !== 'DataHealth' && !rootStore.businessStore.isRuleLoading
  ) {
    return <ShimmerLoader />;
  }

  if (!insightData || !(insightData?.length > 0)) {
    if (!checklistStore.businessChecklists.get(businessesStore.selectedBusinessId) && type === 'DataHealth') {
      return <ShimmerLoader />;
    }
    return (
      <div style={{ width: '100%', paddingTop: 20 }}>
        <Welcome
          img={notes}
          body={`Once you have a client connected, ${lowercaseType} insights about their business will appear here. `}
          heading={uppercaseType}
        />
      </div>
    );
  }

  if (rootStore.pageStore.tabActive === InsightTab.overview) {
    return (
      <Overview />
    );
  }

  if (type === InsightType.DATAHEALTH) {
    return (
      <CheckList />
    );
  }

  if (type === InsightType.REPORT) {
    // If performance report feature flag is set, serve the new performance report
    return splitStore.featureEnabled(SplitEnums.FeatureFlags.ReportCustomisation) ? <PerformanceReportV2 /> : <PerformanceReport />;
  }

  if (type === InsightType.ADVISORY_AI) {
    return (
      <AdvisoryAi />
    );
  }

  const handlePeriodSelection = (newPeriod) => {
    rootStore.timePeriodStore.setProfitabilityPeriodSelected(newPeriod);
  };

  return (
    <>
      {insightData && type === InsightType.PROFITABILITY && (
        <div className='periodSelecterHeader mb-3'>
          <div className='periodSelecterHeader__block'>
            <PeriodGranularity id='profitability-tab-granularity' type={InsightType.PROFITABILITY} />
            <h3 className='text-white mb-0'>Profitability - FY to</h3>
            {rootStore.timePeriodStore.profitabilityPeriodSelected && (
              <Select
                className='profitability-period-dropdown'
                defaultValue={rootStore.timePeriodStore.profitabilityPeriodSelected}
                onChange={handlePeriodSelection}
                size='large'
                options={rootStore.timePeriodStore.profitabilityPeriods}
                value={rootStore.timePeriodStore.profitabilityPeriodSelected}
              />
            )}
          </div>
        </div>
      )}
      <Insight
        insightDataArray={insightData}
        renderActionCentreModal={renderActionCentreModal}
        type={type}
        uppercaseType={uppercaseType}
        lowercaseType={lowercaseType}
        toggleModal={toggleModal}
        tabItems={TabItems}
      />
    </>
  );
};

export default observer(InsightComponent);
