import mp from 'mixpanel-browser';
import { IMixpanelTracking } from '../models/interfaces/mixpanel';

const envCheck = () => (['production', 'staging'].includes(process.env.REACT_APP_MODE));

if (envCheck()) {
  mp.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
  });
}

const Mixpanel = {
  identify: (id) => {
    if (envCheck()) mp.identify(id);
  },
  alias: (id) => {
    if (envCheck()) mp.alias(id);
  },
  track: (name, props = {}) => {
    if (envCheck()) mp.track(name, props);
  },
  people: {
    set: (props) => {
      if (envCheck()) mp.people.set(props);
    },
  },
};

export default Mixpanel;

export const trackMixpanelEvent = (
  { description, properties = null, rootStore }: IMixpanelTracking
): void => {
  if (process.env.NODE_ENV !== 'test') {
    Mixpanel.track(description, {
      businessId: rootStore.businessesStore.selectedBusinessId,
      businessName: rootStore.businessesStore.selectedBusiness?.name || 'No business initialised',
      ...properties,
    });
  }
};
