import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import slugify from 'slugify';
import { Alert } from 'antd';
import { PeriodTypes } from '@aider/aider-period-library';
import { TabMessage, Welcome } from '../../entities/components';
import pointUp from '../../entities/icons/icon_cursor-hand.png';
import notes from '../../entities/icons/notes.png';
import warning from '../../entities/icons/warning.png';
import { ExclamationTriangle } from '../atoms/Icons/ExclamationTriangle';
import { FilteredItems, InsightsOrder } from '../../entities/types';
import { useStore } from '../../stores/Store';
import InsightSettingsInfo from '../atoms/InsightSettingsInfo';
import InsightTrend from '../atoms/InsightTrend';
import DataHealthBannerContainer from '../../components/insights/DataHealthBannerContainer';
import InsightSummary from '../molecules/InsightSummary';
import InsightMissing from '../molecules/InsightMissing';
import PeriodSelection from '../molecules/PeriodSelection';
import InsightChart from './InsightChart';
import { InsightInfo, InsightUse } from '../molecules/EarlyWarning';
import { State } from '../../ts/enums/Colors';
import { InsightType, PeriodSelectionHeaderTitles, PeriodInsights } from '../../ts/enums/Constants';
import Mixpanel from '../../lib/mixpanel';

interface InsightProps {
  insightDataArray: any[],
  renderActionCentreModal: any,
  type: string,
  uppercaseType: string,
  lowercaseType: string,
  toggleModal: any,
  tabItems: any,
}

const Insight = (
  {
    insightDataArray,
    renderActionCentreModal,
    type,
    uppercaseType,
    lowercaseType,
    tabItems,
  }: InsightProps
) => {
  const rootStore = useStore();
  const { localeStore } = rootStore;
  const TabItems = tabItems;
  const instanceTabItems = TabItems[type];
  const { tableGroups } = rootStore.checklistStore.businessChecklists?.get(rootStore.businessesStore.selectedBusinessId)?.get(rootStore.checklistStore.activeChecklistType) || { tableGroups: [] };

  let checkListLength = 0;
  if (instanceTabItems && instanceTabItems.length) {
    checkListLength = tableGroups?.filter((instance) => (
      instanceTabItems.includes(instance.tableGroupType)
    )).length;
  }
  const orderedInsights: any = [];
  const getAvailablePeriods = (periods) => periods.filter((p) => !p.missing);

  useEffect(() => {
    const newPeriods = getAvailablePeriods(insightDataArray?.find((i) => i.insightKey === 'incomeTax')?.periods);
    if (newPeriods.length > 0) {
      rootStore.timePeriodStore.setIncomeTaxPeriod(newPeriods[0]?.periodData?.name);
      rootStore.timePeriodStore.setIncomeTaxPeriods(newPeriods);
    }

    const reconciliationPeriods = getAvailablePeriods(insightDataArray?.find((i) => i.insightKey === 'reconciliation')?.periods);
    if (reconciliationPeriods.length > 0) {
      rootStore.timePeriodStore.setReconciliationPeriod(reconciliationPeriods[0]?.periodData?.name);
      rootStore.timePeriodStore.setReconciliationPeriods(reconciliationPeriods);
    }

    const gstPeriods = getAvailablePeriods(insightDataArray?.find((i) => i.insightKey === 'gst')?.periods);
    if (gstPeriods.length > 0) {
      rootStore.timePeriodStore.setGstPeriod(gstPeriods[0]?.periodData?.name);
      rootStore.timePeriodStore.setGstPeriods(gstPeriods);
    }
  }, [insightDataArray]);

  const usPractice = rootStore.practiceStore.countryCode.toLowerCase() === 'us';
  const filteredInsightsOrder = InsightsOrder?.filter((insight) => !usPractice || !['reconciliation', 'gst'].includes(insight));
  for (let i = 0; i < filteredInsightsOrder?.length; i++) {
    // eslint-disable-next-line react/destructuring-assignment
    let selectedInsight = insightDataArray.find(
      (instance) => instance.insightKey === filteredInsightsOrder[i]
    );
    if (selectedInsight) {
      if (type === InsightType.PROFITABILITY) {
        if (
          selectedInsight.periods.length
          && rootStore.timePeriodStore.periodGranularity === PeriodTypes.MONTHLY
        ) {
          selectedInsight = selectedInsight.periods.find(
            (p) => p?.periodData?.name
              === rootStore.timePeriodStore.profitabilityPeriodSelected
          ) || { missing: true, insightKey: selectedInsight.insightKey };
        } else if (
          selectedInsight.quarters.length
          && rootStore.timePeriodStore.periodGranularity === PeriodTypes.QUARTERLY
        ) {
          selectedInsight = selectedInsight.quarters?.find(
            (q) => q?.periodData?.name
              === rootStore.timePeriodStore.profitabilityPeriodSelected
          ) || { missing: true, insightKey: selectedInsight.insightKey };
        }
      }
      orderedInsights.push(selectedInsight);
    } else {
      orderedInsights.push({
        insightKey: filteredInsightsOrder[i],
        missing: true,
      });
    }
  }

  const availableInsights = orderedInsights?.filter(
    (instance) => !FilteredItems.includes(instance.insightKey)
  );

  const availableInsightsTabs = instanceTabItems && instanceTabItems.length
    ? availableInsights?.filter((instance) => (instanceTabItems.includes(instance.insightKey)))
    : [];

  let alertCount = 0;

  availableInsightsTabs.forEach((insight) => {
    const alertData = insight.alertCount || {};
    Object.values(alertData).forEach((alert: any) => {
      if (alert.level < 0) alertCount += 1;
    });
  });
  rootStore.alertStore.overwriteAlert(rootStore.businessesStore.selectedBusinessId, rootStore.pageStore.tabActive, alertCount);

  // ETL complete but insights are unavailable for this tab, usually due to missing or corrupt data
  if (availableInsightsTabs.length < 1 && checkListLength < 1) {
    if (type === 'cashflow' || type === 'Cashflow') {
      // eslint-disable-next-line react/destructuring-assignment
      const reconciliationInsight = (insightDataArray.find(
        ({ insightKey }) => insightKey === 'reconciliation'
      ));
      // eslint-disable-next-line react/destructuring-assignment
      const reconciliationInsightIndex = (insightDataArray.indexOf(
        reconciliationInsight
      ));
      return (
        <TabMessage
          actionText={
            reconciliationInsight ? 'Send reconciliation request' : null
          }
          actionText2={reconciliationInsight ? 'Go to Xero' : null}
          // eslint-disable-next-line consistent-return
          buttonAction={() => {
            if (reconciliationInsight) {
              rootStore.actionStore.setSubjectKey(
                reconciliationInsight.insightKey,
                false,
                `${reconciliationInsightIndex}-insightChart`
              );
              return renderActionCentreModal('cashflow', reconciliationInsight);
            }
          }}
          buttonAction2={() => {
            window.location.href =
              reconciliationInsight.externalLink
              && reconciliationInsight.externalLink.link;
          }}
          img={warning}
          heading='Cash Flow Forecast not available'
          body={`This could be caused by the client being behind with their reconciliation.
                    The forecast takes into account historical income and spending patterns.
                    If the reconciliation data is very out of date, the forecast would be too unreliable to be shown. \n\nPlease bring the reconciliation up to date or ask your client to do so.`}
        />
      );
    }

    return (
      <Welcome
        img={notes}
        body={`We are still working on providing you insights about your ${lowercaseType}, please check back soon.`}
        heading={`${uppercaseType}`}
      />
    );
  }

  return availableInsights.map((insightData, ix) => {
    const country = rootStore.businessesStore.selectedBusiness?.countryCode;
    let tabOpacity = 1;
    let tabHeight = 'inherit';
    let tabOverflow: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' = 'visible';
    const trackMixpanelEvent = (event) => {
      Mixpanel.track(event, {
        businessId: rootStore.businessStore.selectedBusinessId,
        businessName: rootStore.businessStore.selectedBusinessName,
        tab: type.toLowerCase(),
        insight: insightData.graph.title,
      });
    };

    if (!TabItems[type].includes(insightData.insightKey)) {
      tabOpacity = 0;
      tabHeight = '0px';
      tabOverflow = 'hidden';
    }

    const hoverIndex = 1;

    const reportNameMap = {
      AU: 'BAS / GST Returns',
      CA: 'GST / HST Returns',
      US: 'Sales Tax Reports',
    };

    const salesTaxName = {
      AU: 'GST',
      CA: 'GST / HST',
      US: 'Sales Tax',
    };

    const countryName = {
      AU: 'Australian',
      CA: 'Canadian',
      US: 'United States',
    };

    const alertTitle = () => (<b>Data limitation for {countryName[country]} {reportNameMap[country]}</b>);
    const alertIntroduction = () => {
      if (country === 'AU') {
        return (
          <p className='alertContent'>
            Due to recent changes that Xero made, we are currently unable to get
            some of the finalised BAS from Xero.
          </p>
        );
      }

      return (
        <p className='alertContent'>
          Due to Xero's data syncing limitation for {countryName[country]} businesses, we are currently unable to get the {reportNameMap[country]} from Xero.
        </p>
      );
    };

    const alertContent = () => (
      <>
        {alertIntroduction()}
        <p className='alertContent'>What this means:</p>
        <ul className='alertContent'>
          <li>
            <strong>
              You can still use share the {salesTaxName[country]} to date and forecast with your client BUT...
            </strong>
          </li>
          <li>
            {salesTaxName[country]} amounts for prior periods and the 12-month average is{' '}
            calculated based on transaction data, not the amount on {reportNameMap[country]}.
          </li>
          <li>
            Accuracy of these amounts may be affected by unreconciled items,{' '}
            late claims, and manual adjustments.
          </li>
          <li>
            Use this as an early indication of the {salesTaxName[country]} trends and whether there{' '}
            is a risk that your client will get a higher {salesTaxName[country]} bill for the period.
          </li>
        </ul>
      </>
    );

    const shownSummaries = insightData?.summaries?.filter((summary) => !summary.hidden);

    function getPeriodsForInsight(insightKey: string): any {
      switch (insightKey) {
        case 'incomeTax':
          return rootStore.timePeriodStore.incomeTaxPeriods;
        case 'reconciliation':
          return rootStore.timePeriodStore.reconciliationPeriods;
        case 'gst':
          return rootStore.timePeriodStore.gstPeriods;
        default:
          return [];
      }
    }

    function getInsightData(insightKey: string): any {
      switch (insightKey) {
        case 'incomeTax':
          return rootStore.timePeriodStore.incomeTaxPeriodData;
        case 'reconciliation':
          return rootStore.timePeriodStore.reconciliationPeriodData;
        case 'gst':
          return rootStore.timePeriodStore.gstPeriodData;
        default:
          return insightData;
      }
    }

    function onPeriodChange(newPeriod) {
      if (insightData.insightKey === 'incomeTax') {
        rootStore.timePeriodStore.setIncomeTaxPeriod(newPeriod);
      } else if (insightData.insightKey === 'reconciliation') {
        rootStore.timePeriodStore.setReconciliationPeriod(newPeriod);
      } else if (insightData.insightKey === 'gst') {
        rootStore.timePeriodStore.setGstPeriod(newPeriod);
      }
    }
    return (
      <div
        key={`availableInsight${insightData.insightKey}`}
        id={`${ix}-${TabItems[type].includes(insightData.insightKey)}-${insightData.insightKey}`}
        className='fadedshort'
        style={{
          opacity: tabOpacity,
          maxHeight: tabHeight,
          overflowY: tabOverflow,
        }}
      >

        {insightData.missing ? (
          <InsightMissing insightKey={insightData.insightKey} error={insightData?.error} />
        ) : (
          <>
            {insightDataArray
              && PeriodInsights.includes(insightData.insightKey)
              && rootStore.timePeriodStore.incomeTaxPeriods.length > 0 && (
                <PeriodSelection
                  headerTitle={PeriodSelectionHeaderTitles[insightData.insightKey]}
                  periods={getPeriodsForInsight(insightData.insightKey)}
                  onChange={onPeriodChange}
                />
            )}
            <h3 className='insightTitle'>{localeStore.translation(`insights.${insightData.insightKey}.title`)}{insightData?.earlyWarning ? <InsightInfo /> : null}</h3>

            <DataHealthBannerContainer insightData={insightData} />

            {insightData?.ausMissingBasBusiness && (
              <div style={{ width: 1260 }}>
                <Alert
                  className='basAlert'
                  message={alertTitle()}
                  showIcon
                  icon={<ExclamationTriangle fill={State.warning} strokeWidth={0} />}
                  description={alertContent()}
                  type='warning'
                />
              </div>
            )}

            <div className='insightSummaryContainer'>
              {PeriodInsights.includes(insightData.insightKey) && insightData.insightKey === 'incomeTax'
                && rootStore.timePeriodStore.incomeTaxPeriodSummaries?.length > 0
                && rootStore.timePeriodStore.incomeTaxPeriodSummaries
                  .map((insights, summaryIx) => (
                    <InsightSummary
                      key={`insightSummary${summaryIx * Math.random() * 999999}`}
                      insights={insights}
                      summaryIx={summaryIx}
                      insightTitle={insightData.graph.title}
                      summaryCount={rootStore.timePeriodStore.incomeTaxPeriodSummaries?.length}
                    />
                  ))}
            </div>

            <div className='insightSummaryContainer'>
              {PeriodInsights.includes(insightData.insightKey) && insightData.insightKey === 'reconciliation'
                && rootStore.timePeriodStore.reconciliationPeriodSummaries?.length > 0
                && rootStore.timePeriodStore.reconciliationPeriodSummaries
                  .map((insights, summaryIx) => (
                    <InsightSummary
                      key={`insightSummary${summaryIx * Math.random() * 999999}`}
                      insights={insights}
                      summaryIx={summaryIx}
                      insightTitle={insightData.graph.title}
                      summaryCount={rootStore.timePeriodStore.reconciliationPeriodSummaries?.length}
                    />
                  ))}
            </div>

            <div className='insightSummaryContainer'>
              {PeriodInsights.includes(insightData.insightKey) && insightData.insightKey === 'gst'
                && rootStore.timePeriodStore.gstPeriodSummaries?.length > 0
                && rootStore.timePeriodStore.gstPeriodSummaries
                  .map((insights, summaryIx) => (
                    <InsightSummary
                      key={`insightSummary${summaryIx * Math.random() * 999999}`}
                      insights={insights}
                      summaryIx={summaryIx}
                      insightTitle={insightData.graph.title}
                      summaryCount={rootStore.timePeriodStore.gstPeriodSummaries?.length}
                    />
                  ))}
            </div>

            <div className='insightSummaryContainer'>
              {!PeriodInsights.includes(insightData.insightKey)
                && insightData.summaries
                && shownSummaries[0]
                && shownSummaries.map((insights, summaryIx) => (
                  <InsightSummary
                    key={`insightSummary${summaryIx * Math.random() * 999999}`}
                    insights={insights}
                    summaryIx={summaryIx}
                    insightTitle={insightData.graph.title}
                    summaryCount={shownSummaries.length}
                  />
                ))}
            </div>
            <div
              style={{
                width: '100%',
                height: '60%',
                paddingTop: 0,
                marginBottom: 20,
              }}
            >
              <div className='insightComponentContainer'>
                <div className='insightComponentContainer__child'>
                  <InsightChart
                    insightData={getInsightData(insightData.insightKey)}
                    index={ix}
                    hoverIndex={hoverIndex}
                    chartId={`${insightData.insightKey}-insight-chart`}
                  />
                  <div className='graphHelpText'>
                    <img
                      style={{ marginLeft: 2, marginRight: 5 }}
                      src={pointUp}
                      alt='Finger pointing up at the graph and legend'
                    />{' '}
                    <b>Interactive Graph:</b> Click on the keys in the legend to
                    hide or show a graph element.
                  </div>
                  {insightData?.earlyWarning?.tips && (
                    <InsightUse
                      tips={insightData.earlyWarning.tips}
                      action={insightData?.earlyWarning?.action}
                      identifier={slugify(`${insightData.insightKey} ${insightData.graph.title}`)}
                    />
                  )}
                </div>
                <div className='insightTrendText insightComponentContainer__child'>
                  {insightData.relevantSettings
                    && insightData.relevantSettings.length
                    && rootStore.businessesStore.selectedBusiness.profile
                    && (
                      <InsightSettingsInfo
                        relevantSettings={insightData.relevantSettings}
                        insightKey={insightData.insightKey}
                      />
                    )}
                  <InsightTrend
                    insightData={getInsightData(insightData.insightKey)}
                    trackMixpanelEvent={trackMixpanelEvent}
                    renderActionCentreModal={renderActionCentreModal}
                    ix={ix}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  });
};

export default observer(Insight);
