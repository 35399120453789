// TODO: Refactor this file to remove all the eslint-disable
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import * as Sentry from '@sentry/browser';
import { ApiConstants } from '@aider/constants-library';
import firebase from 'firebase';
import cookie from 'js-cookie';
import qs from 'qs';

import axios from 'axios';
import { displaySignInError } from '../entities/components';
import {
  getUserTokens,
  initializeBackendTokens,
  refreshTokens,
} from '../external/lib/backendTokenHelper';
import { AccountType } from '../entities/types';
import {
  createConnectionWithBackend,
} from '../external/lib/authHelper';
import { loadIntercom } from '../external/lib/intercom';
import {
  addUserWithGoogle,
  addUserWithXero,
  checkUserExists,
} from '../external/lib/userDataHelper';
import businessService from './businessService';
import type { RootStore } from '../stores/Store';
import Mixpanel from '../lib/mixpanel';
import { AIDER_LINKS, AIDER_CONFIG } from '../models/constants/root';
import handleError from '../lib/errorHandler';

export default class AuthService {
  private rootStore: RootStore;

  firebaseApp: any;

  constructor(initStore: RootStore) {
    this.rootStore = initStore;
  }

  initializeFirebaseApp(config) {
    const firebaseAnalyticsAddedConfig = config;
    firebaseAnalyticsAddedConfig.appId = process.env.REACT_APP_FBID;
    this.firebaseApp = firebase.initializeApp(firebaseAnalyticsAddedConfig);
  }

  async handleSignOut(noReload) {
    try {
      const url = `${ApiConstants.apiEndpointsBase.auth}/oauth2/token/signout`;
      const refreshToken = this.rootStore.authStore.refresh_token;
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const body = { refresh_token: refreshToken };

      await axios({
        method: 'post',
        url,
        headers,
        data: qs.stringify(body),
      });
      // eslint-disable-next-line no-empty
    } catch (e) { }

    return new Promise((resolve) => {
      firebase
        .auth()
        .signOut()
        // eslint-disable-next-line consistent-return
        .then(() => {
          Mixpanel.track('Logout');
          cookie.remove('userHasLoggedIn');
          cookie.remove('claimant');
          cookie.remove('unclaimedConnection');
          cookie.remove('inviteAdvisors');
          cookie.remove('skipCreation');
          cookie.remove('redirect');
          this.rootStore.authStore.invalidateCache();
          this.rootStore.authStore.isUserAuthenticated(false);
          this.rootStore.authStore.setUser(null);
          this.rootStore.authStore.setLoading(false);
          this.rootStore.stopStorePersistance();
          if (noReload) {
            return resolve(true);
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    });
  }

  // eslint-disable-next-line consistent-return
  async PaywallRedirect() {
    try {
      const backendTokens = await businessService.refreshPracticeCredentials();
      // @ts-ignore
      const practiceId = this.rootStore.businessStore.practiceId.id;
      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/subscription/portal`;
      return axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).then((data) => {
        console.log(data, 'Data from redirect');
        window.location.href = data.data.portalRedirect;
      });
      // eslint-disable-next-line no-empty
    } catch (e) { }
  }

  // eslint-disable-next-line consistent-return
  async PaywallRedirectCancellation() {
    try {
      const backendTokens = await businessService.refreshPracticeCredentials();
      // @ts-ignore
      const practiceId = this.rootStore.businessStore.practiceId.id;
      const url = `${ApiConstants.apiEndpointsBase.business}/practices/${practiceId}/subscription/checkout`;
      return axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((data) => {
          console.log(data, 'Data from redirect');
          window.location.href = data.data.checkoutRedirect;
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  // eslint-disable-next-line consistent-return
  async initializeTokens(partnerCustomerTokenInRedirectURL) {
    let invitationStatus = 'unused';
    const { links } = this.rootStore.authStore;
    const { user } = this.rootStore.authStore;

    if (!user) {
      return { tokens: {}, invitationStatus };
    }
    let idToken;
    let signInError;
    try {
      idToken = await user.getIdToken();
      const userExists = await checkUserExists(links, user.uid);

      if (userExists === true) {
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token, expires_in, id_token } =
          await initializeBackendTokens(idToken);
        this.rootStore.authStore.isUserAuthenticated(user);
        // eslint-disable-next-line camelcase
        return { tokens: { access_token, refresh_token, id_token, expires_in }, invitationStatus };
      }

      this.rootStore.loadingStore.setLoading('initialLogin');

      if (
        !partnerCustomerTokenInRedirectURL
        && user.providerData[0]
        && user.providerData[0].providerId !== 'google.com'
      ) {
        console.log('Cross method login Google->Xero. Sign the user out.');
        signInError = displaySignInError({
          code: 'none',
          message: 'Cross method login Google->Xero. Login Unsuccessful.',
        });
      } else if (
        partnerCustomerTokenInRedirectURL
        && user.providerData[0]
        && user.providerData[0].providerId === 'google.com'
      ) {
        console.log('Cross method login Xero->Google. Sign the user out.');
        signInError = displaySignInError({
          code: 'none',
          message: 'Cross method login Xero->Google. Login Unsuccessful.',
        });
      } else if (
        user.providerData[0]
        && user.providerData[0].providerId === 'google.com'
      ) {
        const userAddResponse = await addUserWithGoogle(user, this.rootStore.authStore.links);

        invitationStatus = userAddResponse?.invitationStatus;

        const internalTokenAssignment = await initializeBackendTokens(idToken);
        this.rootStore.authStore.isUserAuthenticated(user);
        Mixpanel.track('Sign Up');

        return {
          tokens: {
            access_token: internalTokenAssignment.access_token,
            refresh_token: internalTokenAssignment.refresh_token,
            id_token: internalTokenAssignment.id_token,
          },
          invitationStatus
        };
      } else if (partnerCustomerTokenInRedirectURL) {
        cookie.set('userHasLoggedIn', 'true', { secure: true });
        this.rootStore.authStore.setLoginType('xero');

        const userAddResponse = await addUserWithXero(
          user,
          this.rootStore.authStore.links,
          partnerCustomerTokenInRedirectURL
        );

        invitationStatus = userAddResponse?.invitationStatus;

        const internalTokenAssignment = await initializeBackendTokens(idToken);
        this.rootStore.authStore.isUserAuthenticated(user);
        Mixpanel.track('Sign Up');
        return {
          tokens: {
            access_token: internalTokenAssignment.access_token,
            refresh_token: internalTokenAssignment.refresh_token,
            id_token: internalTokenAssignment.id_token,
          },
          invitationStatus
        };
      } else {
        Sentry.captureMessage(
          'User attempted log in with incorrect sign in method'
        );
        signInError = displaySignInError({
          code: 'none',
          message: 'Incorrect sign in method. Login Unsuccessful.',
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      signInError = displaySignInError(e);
    }

    // Not able to login nor signup, clean up and show error
    this.rootStore.authStore.handleSignOut(true).then(() => {
      this.rootStore.authStore.setSignInError(signInError);
    });

    return { tokens: {}, invitationStatus };
  }

  async initiateConnection() {
    const tokens = await businessService.refreshPracticeCredentials();
    const res = await createConnectionWithBackend(
      tokens.access_token,
      // @ts-ignore
      this.rootStore.businessStore.practiceId.id
    );
    return res;
    /**
     * check up on that connection until its configured (GET) then
     * if it's an orphan connection grab it's config to set up a business,
     * then claim the connection for that business.
     */
  }

  checkCookies() {
    const duplicateConnection = cookie.get('duplicateConnection');
    const reconnectedConnection = cookie.get('reconnectedConnection');
    const renderErrorOccurred = cookie.get('error');
    if (cookie.get('error')) { this.rootStore.businessStore.renderErrorOccurred(renderErrorOccurred); }

    if (duplicateConnection) {
      this.rootStore.businessStore.renderDuplicateWarning(duplicateConnection);
    }
    if (reconnectedConnection) {
      this.rootStore.businessStore.renderReconnectionConfirmation(
        reconnectedConnection
      );
    }
  }

  // Doesn't actually deal with Claim data?
  async handleClaimData(data) {
    if (!data.businesses || data.businesses.length < 1) {
      this.rootStore.connectionsStore.initialConnection = true;
    }
    const practice = data.businesses.filter(
      (i) => i.businessClass === 'management'
    )[0];

    if (practice) {
      this.rootStore.businessStore.setPracticeId(practice);
      this.rootStore.practiceStore.practice = practice;
      this.rootStore.checklistStore.practice = practice;
      await this.rootStore.businessStore.getPracticeAdvisors();

      if (cookie.get('inviteAdvisors') === 'true') {
        this.rootStore.authStore.renderAdvisorsModal(true);
      }
    } else {
      this.rootStore.authStore.setLoading(false);
      console.log('no practice', practice);
    }
  }

  // eslint-disable-next-line consistent-return
  async initializeAuthentication() {
    // This function is called when the App is first loaded, whether or not the user is signed in.
    // The observable returned by firebase is called when the users Auth State changes.

    // For Signup and Google SSO , this Auth state change happens before
    // the user is redirected to Sign In and invoked immediately
    // The authState that is automatically returned once the observable
    // has invoked has providerId which is used to create an account if there is none existing

    // For Xero SSO, the Auth state is triggered once the User has returned
    // to the login page with a Custom Access Token (CAT)
    // The Custom access Token must then be used to Create an Account,
    // if An account does not already exist for this user.

    // Get configuration
    const apiLinks = AIDER_LINKS;
    this.rootStore.authStore.registerLinks(apiLinks);
    const config = AIDER_CONFIG[process.env.REACT_APP_AIDER_ENV].configuration;
    this.rootStore.authStore.setConfig(config);
    this.rootStore.authenticationStore.config = config;

    // Initialize Firebase
    if (!this.firebaseApp) {
      this.initializeFirebaseApp(config.firebase);
    }
    // Store any query parameters
    const query = qs.parse(window.location.search.split('?')[1]);

    // Check redirect token and authenticate
    const { cat, osp } = query;
    const partnerCustomerTokenInRedirectURL = query.token;

    // Remove login tokens from url
    delete query.cat;
    delete query.token;
    delete query.osp;
    const params = qs.stringify(query);
    const newUrl = params !== '' ? `${window.location?.href?.split('?')[0]}?${params}` : window?.location?.href?.split('?')[0];
    window.history.replaceState(null, document?.title, newUrl);

    if (cat) {
      this.rootStore.authStore.setLoginType(osp);
      this.rootStore.authStore.setLoading(true);
      try {
        const authResult = await firebase.auth().signInWithCustomToken(cat);
        const userExists = await checkUserExists(
          this.rootStore.authStore.links,
          authResult.user.uid
        );
        if (userExists === true) {
          this.rootStore.authStore.isUserAuthenticated(authResult);
        }
      } catch (e) {
        handleError({ error: e, transaction: 'sign in with custom token' });
      }
    }
    // Listen for Auth changes and load data
    this.rootStore.authStore.setLoading(false);
    // eslint-disable-next-line consistent-return
    const auth = firebase.auth();
    auth.onAuthStateChanged(async (userAuth) => {
      try {
        // todo abstract loading screen from auth initialization
        if (!userAuth) {
          return;
        }

        this.rootStore.loadingStore.setLoading('auth');

        cookie.set('redirect', 'false', {
          secure: true,
        });

        this.rootStore.authStore.setLoading(true);
        this.rootStore.authStore.setUser(userAuth);
        this.rootStore.userStore.id = userAuth.uid;
        this.rootStore.authStore.setAuthenticatedUser(userAuth);

        cookie.set('userHasLoggedIn', 'true', { secure: true });
        this.rootStore.authStore.setSignInError(null);

        // Now that we have a user, initialise tokens
        let tokens = await refreshTokens({
          access_token: this.rootStore.authStore.persistAccessToken,
          id_token: this.rootStore.authStore.persistIdToken,
          refresh_token: this.rootStore.authStore.persistRefreshToken,
          expires_in: this.rootStore.authStore.persistExpiresIn,
          expiresAt: this.rootStore.authStore.persistExpiry,
        });
        if (!tokens.access_token) {
          const res: any = await this.initializeTokens(
            partnerCustomerTokenInRedirectURL
          );
          this.rootStore.loadingStore.setDoneLoading('appReferral');
          this.rootStore.loadingStore.setDoneLoading('initialLogin');
          tokens = res.tokens;
        }

        // If we couldn't get tokens, then finish the process
        if (!tokens.access_token) {
          handleError({ error: 'Unable to generate access tokens', transaction: 'auth state change', operation: 'Validate access token' });
          return;
        }

        this.rootStore.authStore.setTokens(
          tokens.access_token,
          tokens.refresh_token,
          tokens.expires_in,
          tokens.id_token
        );

        this.rootStore.authStore.persistTokens(
          tokens.access_token,
          tokens.refresh_token,
          tokens.expires_in,
          tokens.id_token
        );

        this.rootStore.authStore.isUserAuthenticated(
          this.rootStore.authStore.user
        );

        // Get the user info from our system
        this.rootStore.authStore.isUserAuthenticated(
          this.rootStore.authStore.user
        );
        const user = await this.getUserDetails();

        this.rootStore.authenticationStore.accessToken = tokens.access_token;
        this.rootStore.authenticationStore.refreshToken = tokens.refresh_token;
        this.rootStore.authenticationStore.expiresIn = tokens.expires_in;

        // If we can't find the user, throw an error
        if (!user) {
          handleError({ error: 'User does not exist', transaction: 'auth state change', operation: 'Validate User' });
          return;
        }

        // Initialise analytics tools
        const {
          accountType,
          displayName,
          email,
          emailVerified,
          familyName,
          givenName,
          notificationStatus,
          phoneNumber,
          providerId,
        } = user;
        Mixpanel.identify(this.rootStore.userStore.id);
        Mixpanel.people.set({
          userId: this.rootStore.userStore.id,
          accountType,
          displayName,
          $email: email,
          emailVerified,
          $last_name: familyName,
          $first_name: givenName,
          $name: `${givenName} ${familyName}`,
          notificationStatus,
          $phone: phoneNumber,
          providerId,
        });

        // Authentication is done. Now check for actions in cookies and query
        this.rootStore.authStore.setLoading(false);
        this.checkCookies();

        // Start loading data
        await this.loadUserData();
        loadIntercom(this.rootStore, tokens, user);

        this.rootStore.loadingStore.setDoneLoading('auth');

        return;
      } catch (e) {
        handleError({ error: e, transaction: 'auth state change', operation: 'Performing validation on auth change' });
        this.rootStore.authStore.setLoading(false);
        this.rootStore.loadingStore.setDoneLoading('auth');
        this.rootStore.authStore.invalidateCache();
      }
    }, (error) => {
      handleError({ error, transaction: 'auth state change', operation: 'Listening for Auth Changes' });
    });
  }

  async getUserDetails() {
    try {
      const user = await this.rootStore.businessStore.getUserDetail(
        this.rootStore.userStore.id
      );
      if (user?.accountType === AccountType.DigitalAssistant) {
        Sentry.captureMessage('digital_app account detected');
        this.rootStore.authStore.setRenderAppAccountKickback(true);
        this.rootStore.pageStore.setNavBarActive('CLIENT_LIST');
      }
      return user;
    } catch (e) {
      handleError({ error: e, transaction: 'get user details' });
      return null;
    }
  }

  async loadUserData() {
    await this.rootStore.businessesStore.fetchBusinessConnection();
    this.rootStore.GraphStore.getLineOfBusinessList();
    if (this.rootStore.authStore.authDetail) {
      this.rootStore.businessStore.updateAuthDetailSync();
    }
    this.rootStore.tagStore.getTags();
  }

  async impersonateUser(userId) {
    cookie.set('impersonatingUser', 'true', { secure: true });
    await getUserTokens(userId);
    this.rootStore.authStore.setUser({ uid: userId });
    const user = await this.getUserDetails();
    this.rootStore.authStore.setUser({
      uid: userId,
      name: user.displayName,
      email: user.email,
    });
    await this.loadUserData();
  }

  async stopImpersonatingUser() {
    const userId = this.rootStore.authStore.authenticatedUser.uid;
    await getUserTokens(userId);
    this.rootStore.authStore.setUser({ uid: userId });
    const user = await this.getUserDetails();
    this.rootStore.authStore.setUser({
      uid: userId,
      name: user.displayName,
      email: user.email,
    });
    await this.loadUserData();
    cookie.remove('impersonatingUser');
  }

  // eslint-disable-next-line class-methods-use-this
  async handleBulkConnectionSuccess(
    connectionSuccess: boolean,
    successArray?: any
  ) {
    const { businessStore, authStore } = this.rootStore;
    if (!connectionSuccess) {
      // There are items in the array of successful connections?
      if (successArray?.length >= 1) {
        businessStore.setBusinessFirstTimeSetup(true);
        businessStore.selectBusiness2(successArray[0].businessId);
      }
      authStore.setBulkConnectionsStatus(null);
      businessStore.setBulkConnectionStatsNull(true);
    }

    await businessStore.updateAuthDetailSync();

    businessStore.setSavingBusinessConnection(false);
  }
}
