import React from 'react';
import { observer } from 'mobx-react';
import Format, { FormatTypes, ValueTypes } from '@aider/aider-formatting-library';
import { Tooltip } from 'antd';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import { useStore } from '../../stores/Store';
import { ResyncIcon } from '../icons';
import Notification from '../Notification';
import { sectionResyncTooltips } from '../../models/constants/components';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import ResyncError from '../manualResync/ResyncError';

export enum resyncableSections {
  bankReconciliation = 'bankReconciliation',
}

const SectionResync = (
  { checklistId, sectionId, lastUpdated, firestoreUpdatedAt, buttonOnly }
    : { checklistId: string, sectionId: string, lastUpdated: string, firestoreUpdatedAt: string, buttonOnly?: boolean }
) => {
  const { businessesStore, checklistStore, resyncStore } = useStore();
  const [syncing, setSyncing] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState(null);
  const [hasResyncError, setHasResyncError] = React.useState(false);
  const format = new Format(businessesStore.selectedBusiness?.currencyCode, businessesStore.selectedBusiness?.countryCode);
  const storeSyncing = resyncStore.resyncingBusinesses.has(businessesStore.selectedBusinessId) && resyncStore.resyncingBusinesses.get(businessesStore.selectedBusinessId) === true;

  const businessDetails = businessesStore.businesses.get(businessesStore.selectedBusinessId);
  const [ospKey] = businessDetails.connectedOsps || [null];

  const clearSync = () => {
    setSyncing(false);
    resyncStore.resyncingBusinesses.set(businessesStore.selectedBusinessId, false);
    resyncStore.loadingBusinesses.set(businessesStore.selectedBusinessId, false);
  };

  React.useEffect(() => {
    const checklistUpdated = new Date(lastUpdated);
    const firestoreUpdated = new Date(firestoreUpdatedAt);
    let lastUpd = checklistUpdated;

    if (firestoreUpdatedAt) {
      lastUpd = firestoreUpdated;
    }

    if (storeSyncing) {
      checklistStore.retrieveChecklist(checklistStore.activeChecklistType, businessesStore.selectedBusinessId)
        .then(clearSync);
    }
    setUpdatedAt(lastUpd);

    if (resyncStore.dataStatusErrorData(businessesStore.selectedBusinessId).length) {
      setHasResyncError(true);
    }
  }, [lastUpdated, firestoreUpdatedAt]);

  const handleResync = () => {
    if (syncing) return;
    setSyncing(true);
    resyncStore.resyncingBusinesses.set(businessesStore.selectedBusinessId, true);
    checklistStore.resyncChecklistSection(checklistId, sectionId)
      .then(() => {
        Notification({
          type: 'info',
          title: 'Resyncing Section',
          description: 'Resyncing has begun and will update automatically once completed.',
        });
      })
      .catch(() => {
        clearSync();
        Notification({
          type: 'error',
          title: 'Resyncing Section',
          description: 'An error occurred while resyncing the section. Please try again later.',
        });
      });
  };

  const Button = buttonOnly ? ButtonPrimary : ButtonSecondary;

  return (
    updatedAt && (
      <p className='section-resync'>{!buttonOnly && (
        <>
          Updated: {
            format.formatValue({
              value: updatedAt.toISOString(),
              format: ValueTypes.formatDate,
              dateFormat: { day: FormatTypes.short, month: FormatTypes.short, time: true },
            })
          }
        </>
      )}
        <div className='section-resync__status'> {/** TODO: Fix error: <div> cannot appear as a decendant of <p> */}
          <ResyncError businessId={businessesStore.selectedBusinessId} />
          {(!!resyncableSections[sectionId] || hasResyncError) && (
            <Tooltip
              title={(
                <div className='aider-tooltip'>
                  <h4>{sectionResyncTooltips?.[sectionId]?.title}</h4>
                  <p>{sectionResyncTooltips?.[sectionId]?.content ?? 'Click to resync data'}</p>
                </div>
              )}
              placement='bottom'
            >
              <Button
                className={`${hasResyncError ? 'resync-error__compliance-section' : ''}`}
                ghost
                size='small'
                onClick={handleResync}
                disabled={syncing || storeSyncing}
                mixpanelEvent={`Business Resync - ${sectionId}`}
                mixpanelProperties={{ checklistId, sectionId, businessOsp: ospKey }}
              >
                <ResyncIcon className={`${syncing || storeSyncing ? 'anticon-spin' : ''}`} />
                {syncing || storeSyncing ? 'Resyncing Section' : hasResyncError ? 'Resync' : 'Quick Resync'}
              </Button>
            </Tooltip>
          )}
        </div>
      </p>
    )
  );
};

SectionResync.defaultProps = {
  buttonOnly: false,
};

export default observer(SectionResync);
