import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { DashboardEnums } from '@aider/constants-library';
import { DashboardColumnInsightMap } from '../../models/enums/components';
import DashboardCellIcon from './DashboardCellIcon';
import { cleanMainValue, cleanSubtext, getAlertValue, getCellClassName, getIconPosition } from '../../lib/componentHelpers/dashboardHelpers';
import { useStore } from '../../stores/Store';
import ClientListRedirect from './ClientListRedirect';
import { DashboardColumns } from '../../models/constants/components';
import { InsightTab } from '../../stores/v1/pageStore';

const DashboardCellSelfObtaining = ({ businessId, column }: { businessId: string, column: string }) => {
  const { dashboardStore } = useStore();
  const navigate = useNavigate();

  const business = dashboardStore.businesses.get(businessId);
  const colType = DashboardColumns[column]?.type;
  const insight = business.data?.find((d) => d.key === column);
  const groupPositions = dashboardStore.columnPosition(column);
  let reportStatus: string = null;

  /**
   * This is required because due to data availability at dashboard calculation (in insights module),
   * the GST Period Completion reportStatus property is part of the GST Forecast insight.
   */
  if (colType === DashboardEnums.ColumnTypes.gst) {
    reportStatus = business.data?.find((d) => d.key === 'gstestimate')?.reportStatus;
  }
  const alert = getAlertValue(typeof insight.alert, insight.alert);

  const handleCellClick = () => {
    // This is a workaround using the existing redirect functionality until we refactor
    // the insights page
    ClientListRedirect({ businessId, businessName: business.businessName }, DashboardColumnInsightMap?.[insight?.key] || InsightTab.dataCompliance);
    navigate('/insights');
  };

  // Render
  return (
    <div className={getCellClassName(groupPositions, alert, colType, column, dashboardStore)} onClick={handleCellClick}>
      <div className='dashboardCell__data'>
        <div className='dashboardCell__value'>{cleanMainValue(column, insight.current.formattedValue)}</div>
        <div className='dashboardCell__subtext'>{cleanSubtext(insight.current.formattedValue, insight.change.formattedValue)}</div>
      </div>
      <div className={`dashboardCell__icon dashboardCell__icon--${getIconPosition(colType)}`}>
        {(colType === DashboardEnums.ColumnTypes.alert) && <DashboardCellIcon colType={colType} alertValue={insight.alert} />}
        {(colType === DashboardEnums.ColumnTypes.trend) && <DashboardCellIcon colType={colType} trendDirection={insight.trendDirection} />}
        {(colType === DashboardEnums.ColumnTypes.gst) && <DashboardCellIcon colType={colType} status={reportStatus} businessId={business.businessId} />}
      </div>
    </div>
  );
};

export default observer(DashboardCellSelfObtaining);
