import React, { useEffect } from 'react';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import EditorRowActions from './EditorRowActions';
import EditorRowContent from './EditorRowContent';
import { useStore } from '../../stores/Store';
import { usePrevious } from '../../entities/utils';

const EditorRow = ({
  block,
  blockInstance,
} : {
  block: PracticeTypes.ReportBlock,
  blockInstance: number,
}) => {
  const { reportTemplateStore } = useStore();
  const [editedContent, setEditedContent] = React.useState(null);
  const isEditing = reportTemplateStore.editBlock === block.id;
  const wasEditing = usePrevious(isEditing);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditing && !reportTemplateStore.editedPerformanceReportTemplate) {
      reportTemplateStore.prepEditReportTemplate();
    }
    if (!isEditing && wasEditing && editedContent) {
      reportTemplateStore.editReportTemplate(editedContent, block.id);
      setEditedContent(null);
    }

    return () => {
      form.resetFields();
      setEditedContent(null);
    };
  }, [isEditing]);

  const handleFormFieldChange = (changedFields) => {
    setEditedContent(changedFields[block.id]);
    reportTemplateStore.inject = null;
    reportTemplateStore.editReportTemplate(changedFields[block.id], block.id);
  };

  return (
    <Form form={form} className='report-editor__content__row' onValuesChange={handleFormFieldChange} key={`${block.id}-row`}>
      <EditorRowActions
        key={`${block.id}-actions`}
        block={block}
        blockInstance={blockInstance}
      />
      <EditorRowContent
        key={`${block.id}-content`}
        block={block}
        blockInstance={blockInstance}
      />
    </Form>
  );
};

export default observer(EditorRow);
