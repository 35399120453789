import * as React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { renderCellAlertLevel } from '../../entities/utils';
import renderVisibility from '../atoms/RenderVisibility';

interface InsightSummaryProps {
  insights: any;
  summaryIx: number;
  insightTitle: string;
  summaryCount;
}

const InsightSummary = ({
  insights,
  summaryIx,
  insightTitle,
  summaryCount // eslint-disable-line @typescript-eslint/no-unused-vars
}: InsightSummaryProps) => {
  const isGSTForecast = insightTitle.includes('forecast');

  return (
    <div
      id={`ix${summaryIx}`}
      onMouseEnter={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ display: 'grid' }}
      className={`insightDataBlock ${
        insights.severity && renderCellAlertLevel(insights.severity.alertLevel)
      } ${isGSTForecast ? 'GSTForecastAlert' : ''}`}
    >
      <span className='overviewIndicatorIcon'>
        {insights.headline.overviewIcon && (
          <OverlayTrigger
            key='bottom'
            placement='bottom'
            overlay={
              <Tooltip id='tooltip-bottom'>Shown on the Dashboard</Tooltip>
            }
          >
            <img height={25} src={insights.headline.overviewIcon} alt='D' />
          </OverlayTrigger>
        )}
      </span>
      <span className='insightDataText'>
        {insights.headline.label.split('\n').map((str, i) => (
          <p key={`insight_headline_label-${i * Math.random() * 999999}`}>{str}</p>
        ))}
      </span>
      <span className='insightDataPoint'>
        {insights.headline.formattedValue}
      </span>
      <span
        className={`insightDataPoint ${
          insights.severity && renderVisibility(insights.severity.alertLevel)
        }`}
        style={{ fontSize: 20 }}
      >
        {insights.headline.icon && (
          <img
            style={{ marginBottom: 7 }}
            height={25}
            src={insights.headline.icon}
            alt=''
          />
        )}{' '}
        {insights.headline.percentageChange}{' '}
      </span>
    </div>
  );
};

export default InsightSummary;
